import React from "react";
import { Stack } from "react-bootstrap";
import "./_PhoneDetailLine.css";
import LabelGroup from "../../../shared/components/label/_LabelGroup";

const PhoneDetailLine = ({ title, name, phone }) => {
  return (
    <Stack direction="horizontal" className="border-top border-1">
      <LabelGroup text={name ?? "N/A"} description={title} subText={phone} />
      {name && (
        <a
          title={`Call ${name}`}
          href={`tel:${phone}`}
          className="m-0 btn btn-link"
        >
          <i className="ri-phone-fill display-6"></i>
        </a>
      )}
    </Stack>
  );
};

export default PhoneDetailLine;
