import React from "react";
import { store } from "./redux/store.js";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as routes from "./routing/nav_routes_builder.js";
import AppSWUpdate from "./AppSWUpdate.jsx";

const router = createBrowserRouter(routes.build());

function App() {
  return (
    <div>
      <AppSWUpdate />
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </div>
  );
}

export default App;
