import {
  CAT_1_PERIODIC_RESULTS_INDEXES,
  CAT_1_PERIODIC_STATUS,
  CAT_5_INDEXES,
  CAT_5_STATUS,
  CAT_TYPES,
} from "../constants/inspectionConsts";
import {
  MOBILE_STATUS,
  MOBILE_STATUS_BUTTON,
} from "../constants/inspectionConsts";

export const isCat1 = (inspectionLine) => {
  const inspectionTypeId = inspectionLine?.inspectionTypeId;
  return (
    inspectionTypeId === CAT_TYPES.CAT1 ||
    inspectionTypeId === CAT_TYPES.CAT1_CAT5 ||
    isPeriodic(inspectionLine)
  );
};

export const isCat5 = (inspectionLine) => {
  const inspectionTypeId = inspectionLine?.inspectionTypeId;
  return (
    inspectionTypeId === CAT_TYPES.CAT1_CAT5 ||
    inspectionTypeId === CAT_TYPES.CAT5
  );
};

export const isCat5Only = (inspectionLine) => {
  const inspectionTypeId = inspectionLine?.inspectionTypeId;
  return (
    inspectionTypeId === CAT_TYPES.CAT5
  );
};

export const isPeriodic = (inspectionLine) => {
  const inspectionTypeId = inspectionLine?.inspectionTypeId;
  return inspectionTypeId === CAT_TYPES.PERIODIC;
};

export const getCompleteButtonLabel = (mobileStatus) => {
  switch (mobileStatus) {
    case MOBILE_STATUS.TODO:
      return `${MOBILE_STATUS_BUTTON.COMPLETE}`;
    default:
      return `Revert to ${MOBILE_STATUS_BUTTON.TODO}`;
  }
};

export const getCompleteButtonVariant = (isCompleted, mobileStatus) => {
  if (isCompleted) return "secondary";
  if (mobileStatus === MOBILE_STATUS.COMPLETE) return "warning";
  return "success";
};

export const cat1ResultToIndex = (res) => {
  switch (res) {
    case CAT_1_PERIODIC_STATUS.SATISFACTORY:
      return CAT_1_PERIODIC_RESULTS_INDEXES.SATISFACTORY;
    case CAT_1_PERIODIC_STATUS.UNSATISFACTORY:
      return CAT_1_PERIODIC_RESULTS_INDEXES.UNSATISFACTORY;
    default:
      return CAT_1_PERIODIC_RESULTS_INDEXES.NA;
  }
};

export const cat1IndexToResult = (inspectionResultValue) => {
  switch (inspectionResultValue) {
    case CAT_1_PERIODIC_RESULTS_INDEXES.SATISFACTORY:
      return CAT_1_PERIODIC_STATUS.SATISFACTORY;
    case CAT_1_PERIODIC_RESULTS_INDEXES.UNSATISFACTORY:
      return CAT_1_PERIODIC_STATUS.UNSATISFACTORY;
    default:
      return CAT_1_PERIODIC_STATUS.NA;
  }
};

export const cat5ResultToIndex = (inspectionResultValue) => {
  switch (inspectionResultValue) {
    case CAT_5_STATUS.SATISFACTORY:
      return CAT_5_INDEXES.SATISFACTORY;
    default:
      return CAT_5_INDEXES.NA;
  }
};

export const cat5IndexToResult = (res) => {
  switch (res) {
    case CAT_5_INDEXES.SATISFACTORY:
      return CAT_5_STATUS.SATISFACTORY;
    default:
      return CAT_5_STATUS.NA;
  }
};

export const isCat1NA = (line) => {
  if (!line) return false;
  return line.cat1Results === CAT_1_PERIODIC_RESULTS_INDEXES.NA;
};

export const isCat5NA = (line) => {
  if (!line) return false;
  return line.cat5Results === CAT_5_INDEXES.NA;
};

export const isCat1Unsatisfactory = (line) => {
  if (!line) return false;
  return line.cat1Results === CAT_1_PERIODIC_RESULTS_INDEXES.UNSATISFACTORY;
};

export const isCat1Satisfactory = (line) => {
  if (!line) return false;
  return line.cat1Results === CAT_1_PERIODIC_RESULTS_INDEXES.SATISFACTORY;
};

export const isCat5Satisfactory = (line) => {
  if (!line) return false;
  return line.cat5Results === CAT_5_INDEXES.SATISFACTORY;
};

export const hideDeficiencies = (line) => {
  if (!line) return false;

  if (isCat1Unsatisfactory(line)) {
    return false;
  }

  return isCat1Satisfactory(line) || isCat5Satisfactory(line);
};

export const getMobileStatusLabel = (isCompleted, mobileStatus) => {
  if (isCompleted) return "Completed";
  if (mobileStatus === MOBILE_STATUS.COMPLETE) return "Mobile Complete";
  return "ToDo";
};

export const getStatusBadgeColor = (isCompleted, mobileStatus) => {
  if (isCompleted) return "bg-danger";
  if (mobileStatus === MOBILE_STATUS.COMPLETE) return "bg-purple";
  return "bg-success";
};

export const getBorderColor = (isCompleted, mobileStatus) => {
  if (isCompleted) return "border-danger";
  if (mobileStatus === MOBILE_STATUS.COMPLETE) return "border-purple";
  return "border-success";
};

export const getLockedStatus = (inspectionLine) => {
  if (!inspectionLine) return { isLocked: true, isLineCompleted: false };
  if (inspectionLine.lineCompleted)
    return { isLocked: true, isLineCompleted: true };
  if (inspectionLine.mobileStatus === MOBILE_STATUS.COMPLETE)
    return { isLocked: true, isLineCompleted: false };

  return { isLocked: false, isLineCompleted: false };
};
