import React from "react";
import { Stack } from "react-bootstrap";
import ResourceItem from "../../shared/components/resource-item/_ResourceItem";
import { INSPECTION_LABELS } from "../../../services/constants/inspectionConsts";
import {
  INSPECTION_ROUTES_PARAMS,
  INTERNAL_ROUTES,
} from "../../../routing/nav_routes";
import { useNavigate } from "react-router-dom";
import {
  hideDeficiencies,
  isPeriodic,
} from "../../../services/utils/inspectionType";

const InspectionResources = ({
  isLocked,
  nbOfDeficiencies,
  inspectionLine,
}) => {
  const navigate = useNavigate();
  const inspectionLineId = inspectionLine.inspectionLineId;
  const periodic = isPeriodic(inspectionLine);
  const hideDefs = hideDeficiencies(inspectionLine);

  const onInspectorClick = () => {
    if (isLocked) return;

    navigate(
      INTERNAL_ROUTES.INSPECTION_INSPECTOR.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onBrakeComplianceClick = () => {
    if (isLocked) return;

    navigate(
      INTERNAL_ROUTES.INSPECTION_BRAKE_COMPLIANCE.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onDeficienciesClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION_DEFICIENCIES.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onViewCommentsClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION_COMMENTS.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onViewDeficiencyHistoryClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION_DEFICIENCY_HISTORY.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  const onViewPhotosClick = () => {
    navigate(
      INTERNAL_ROUTES.INSPECTION_PHOTOS.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        inspectionLineId
      )
    );
  };

  return (
    <Stack direction="vertical" gap={2}>
      <ResourceItem
        description={INSPECTION_LABELS.INSPECTION_BRAKE_COMPLIANCE}
        action={INSPECTION_LABELS.INSPECTION_BRAKE_COMPLIANCE_DESCRIPTION(
          inspectionLine?.brakeComplianceDisplay,
          inspectionLine?.machineManufacturer
        )}
        onClick={onBrakeComplianceClick}
        disabled={isLocked}
      />
      {!periodic && (
        <>
          <p className="top-border"></p>
          <ResourceItem
            description={INSPECTION_LABELS.INSPECTION_INSPECTOR}
            action={INSPECTION_LABELS.INSPECTION_INSPECTOR_DESCRIPTION(
              inspectionLine?.elevatorContactFullName,
              inspectionLine?.elevatorContactLicense
            )}
            onClick={onInspectorClick}
            disabled={isLocked}
          />
        </>
      )}
      {!hideDefs && (
        <>
          <p className="top-border"></p>
          <ResourceItem
            description={INSPECTION_LABELS.INSPECTION_DEF(nbOfDeficiencies)}
            action={INSPECTION_LABELS.INSPECTION_DEF_DESCRIPTION}
            onClick={onDeficienciesClick}
          />
        </>
      )}
      <p className="top-border"></p>
      <ResourceItem
        description={INSPECTION_LABELS.INSPECTION_COMMENTS}
        action={
          inspectionLine?.comments ??
          INSPECTION_LABELS.INSPECTION_COMMENTS_DESCRIPTION
        }
        onClick={onViewCommentsClick}
      />
      <p className="top-border"></p>
      <ResourceItem
        description={INSPECTION_LABELS.INSPECTION_DEF_HISTORY}
        action={INSPECTION_LABELS.INSPECTION_DEF_HISTORY_DESCRIPTION}
        onClick={onViewDeficiencyHistoryClick}
      />
      <p className="top-border"></p>
      <ResourceItem
        description={INSPECTION_LABELS.INSPECTION_IMAGES}
        action={INSPECTION_LABELS.INSPECTION_IMAGES_DESCRIPTION}
        onClick={onViewPhotosClick}
      />
    </Stack>
  );
};

export default InspectionResources;
