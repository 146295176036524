import { useGetPingQuery } from "../redux/features/ping/ping";
import { API_MESSAGES } from "../services/constants/commonMessages";
import { buildFromQuery } from "../services/utils/statusUtils";
import * as statusBuilder from "../services/utils/statusUtils";

export const useApiPing = () => {
  const ping = useGetPingQuery();

  const failMsg = API_MESSAGES.API_NOT_RESPONDING;
  const okMsg = API_MESSAGES.API_IS_HEALTHY;
  let status = buildFromQuery(ping, okMsg, failMsg);

  const loading = ping.isLoading || ping.isFetching;

  status = loading
    ? statusBuilder.loading(API_MESSAGES.API_PING_IN_PROGRESS)
    : status;

  const refetch = () => {
    ping.refetch();
  };

  //console.log("status", status, ping);

  return {
    refetch,
    isLoading: loading,
    isSuccess: !loading && !status.hasError,
    status,
  };
};
