import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../header/_Header";
import ResourceList from "./_ResourceList";
import PageSpinner from "../spinner/_PageSpinner";
import PageError from "../error/_PageError";

const ResourceListPage = ({
  title,
  subTitle,
  resources,
  onSelect,
  isSelected,
  onBack,
  refresh,
  isLoading,
  before,
  after,
  error
}) => {
  const render = () => {
    if (isLoading) return <PageSpinner show={true} />;
    if (error) return <PageError status={error} show={true} />;

    return (
      <>
        {before}
        <ResourceList
          resources={resources}
          onSelect={onSelect}
          isSelected={isSelected}
          disabled={isLoading}
        />
        {after}
      </>
    );
  };

  //console.log('resources', resources);
  return (
    <Container>
      <Row>
        <Col>
          <Header title={title} subTitle={subTitle} onBack={onBack} refresh={refresh} />
        </Col>
      </Row>

      <Row>
        <Col>{render()}</Col>
      </Row>
    </Container>
  );
};

export default ResourceListPage;
