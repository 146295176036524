import React, { useState } from "react";

//services
import {
  CAT5RESULTS,
  CAT_5_STATUS,
} from "../../../services/constants/inspectionConsts";
import {
  cat5IndexToResult,
  cat5ResultToIndex,
  isCat5,
  isCat5Only,
} from "../../../services/utils/inspectionType";
import { buildFromMutation } from "../../../services/utils/statusUtils";
import {
  INSPECTION_CONFIRMATION,
  INSPECTION_ERRORS,
  INSPECTION_SUCCESS,
} from "../../../services/constants/inspectionMessages";

//hooks
import { useUpdateInspDeviceCat5ResultsMutation } from "../../../redux/features/inspectionLine/inspectionApiSlice";

//components
import Label from "../../shared/components/label/_Label";
import ToggleButtonGroup from "../../shared/components/button-group/_ToggleButtonGroup";
import ConfirmModal from "../../shared/components/modal/_ConfirmModal";

const Cat5Results = ({
  inspectionLine,
  nbOfDeficiencies,
  disabled,
  onUpdateStatus,
  refetch,
}) => {
  const [cat5ConfirmSatisfactory, setCat5ConfirmSatisfactory] = useState(false);
  const [update, mutation] = useUpdateInspDeviceCat5ResultsMutation();
  const isLoading = mutation.isLoading;
  const cat5Visible = isCat5(inspectionLine);

  const updateCat5 = async (res) => {
    const index = cat5ResultToIndex(res);
    if (inspectionLine.cat5Results === index) return;

    let payload = {
      inspectionLineId: inspectionLine?.inspectionLineId,
      cat5Index: index,
    };

    const response = await update(payload);
    const errorMsg = INSPECTION_ERRORS.FAILED_TO_UPDATE_CAT_5;
    const successMsg = INSPECTION_SUCCESS.UPDATED_CAT_5;
    const status = buildFromMutation(response, successMsg, errorMsg);
    onUpdateStatus(status);

    //Updating to Satisfactory will delete all the deficiencies on the BE,
    //so lets do a deficiency refetch to update redux
    if (showConfirmation(res)) refetch();
  };

  const showConfirmation = (res) => {
    return (
      isCat5Only(inspectionLine) &&
      res === CAT_5_STATUS.SATISFACTORY &&
      nbOfDeficiencies > 0
    );
  };

  const onSelectCat5 = (res) => {
    if (showConfirmation(res)) {
      setCat5ConfirmSatisfactory(true);
      return;
    }

    updateCat5(res);
  };

  const confirmCat5Satisfactory = () => {
    setCat5ConfirmSatisfactory(false);
    updateCat5(CAT_5_STATUS.SATISFACTORY);
  };

  const isResultDisabled = (res) => {
    //if (res === CAT_5_STATUS.SATISFACTORY && nbOfDeficiencies > 0) return true;

    return false;
  };

  const renderCat5 = () => {
    if (!cat5Visible) return <></>;

    return (
      <>
        <ConfirmModal
          show={cat5ConfirmSatisfactory}
          title={"Warning"}
          text={INSPECTION_CONFIRMATION.REMOVE_ALL_DEFS_CONFIRMATION}
          onConfirm={confirmCat5Satisfactory}
          onClose={() => setCat5ConfirmSatisfactory(false)}
        />
        <p className="top-border"></p>
        <Label text={"CAT 5 Results"} className={"fw-light"} />
        <ToggleButtonGroup
          name={"cat5"}
          possibleResults={CAT5RESULTS}
          selectedResult={cat5IndexToResult(inspectionLine.cat5Results)}
          onSelect={onSelectCat5}
          disabled={disabled || isLoading}
          isResultDisabled={isResultDisabled}
        />
      </>
    );
  };

  return <>{renderCat5()}</>;
};

export default Cat5Results;
