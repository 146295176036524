import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// components
import DeficiencyDetailMain from "./components/_DeficiencyDetailMain";
import ResourceListPage from "../../shared/components/resource-list/_ResourceListPage";

//services
import { DEFICIENCY_DETAILS_COMPONENTS } from "./components/_DeficiencyDetailComponents";
import { useResources } from "../../../hooks/useResources";
import { useInspectionLine } from "../../../hooks/useInspectionLine";
import { useGetDeficiency } from "../../../hooks/useGetDeficinecy";
import ConfirmModal from "../../shared/components/modal/_ConfirmModal";
import { DEF_DETAIL_MESSAGES } from "./_DeficiencyDetailsConsts";

const InspectionDeficiencyDetails = () => {
  const navigate = useNavigate();
  const { elevatorParts, violations, remedies } = useResources();

  const {
    inspectionLine: line,
    isLoading: insLoading,
    status: insStatus,
  } = useInspectionLine();
  const {
    deficiency,
    isSuccess: defSuccess,
    isLoading: defIsLoading,
    status: defGetStatus,
  } = useGetDeficiency();

  const [state, setState] = useState({
    componentToShow: DEFICIENCY_DETAILS_COMPONENTS.MAIN,
  });

  const initialViolation = {
    id: -1,
    description: "Select Violation",
    item: "",
  };
  const initialRemedy = { id: -1, description: "Select Remedy", item: "" };
  const initialPart = {
    id: -1,
    description: "Select Part",
    item: "",
    location: "",
    isBuildingResponsibility: false,
  };

  const [selectedDeficiency, setSelectedDeficiency] = useState({
    part: null,
    violation: null,
    remedy: null,
  });

  const [mappedDeficiency, setMappedDeficiency] = useState({
    part: null,
    violation: null,
    remedy: null,
  });

  const [showConfirmPendingAbandon, setShowConfirmPendingAbandon] =
    useState(false);

  const isLoading = defIsLoading || insLoading;
  const violation =
    selectedDeficiency.violation ??
    mappedDeficiency.violation ??
    initialViolation;
  const part = selectedDeficiency.part ?? mappedDeficiency.part ?? initialPart;
  const remedy =
    selectedDeficiency.remedy ?? mappedDeficiency.remedy ?? initialRemedy;

  useEffect(() => {
    if (deficiency) {
      setMappedDeficiency({
        part: deficiency.elevatorPart
          ? {
              id: deficiency.elevatorPart.elevatorPartId,
              description: deficiency.elevatorPart.description,
              item: deficiency.elevatorPart.item,
              location: deficiency.elevatorPart.location,
              isBuildingResponsibility:
                deficiency.elevatorPart.isBuildingResponsibility,
            }
          : null,
        violation: deficiency.violation
          ? {
              id: deficiency.violation.violationId,
              description: deficiency.violation.description,
              item: deficiency.violation.item,
            }
          : null,
        remedy: deficiency.remedy
          ? {
              id: deficiency.remedy.remedyId,
              description: deficiency.remedy.description,
              item: deficiency.remedy.item,
            }
          : null,
      });
    } else {
      console.log("Deficiency not found - we are going to insert");
    }
  }, [deficiency]);

  const isOriginalModified = () => {
    if (!deficiency) return false;

    const isPartModified =
      selectedDeficiency.part &&
      selectedDeficiency.part.id !== deficiency.part?.partId;
    const isViolationModified =
      selectedDeficiency.violation &&
      selectedDeficiency.violation.id !== deficiency.violation?.violationId;
    const isRemedyModified =
      selectedDeficiency.remedy &&
      selectedDeficiency.remedy.id !== deficiency.remedy?.remedyId;

    if (
      deficiency &&
      (isPartModified || isViolationModified || isRemedyModified)
    ) {
      console.log("original modified", true);
      return true;
    }
    console.log("original modified", false);

    return false;
  };

  const isIncomplete = () => {
    if (
      !selectedDeficiency.part ||
      !selectedDeficiency.violation ||
      !selectedDeficiency.remedy
    ) {
      return true;
    }

    return false;
  };

  const isDisabled = () => {
    //Brand new def, so there is no def from the server and

    console.log("ispending", deficiency);
    if (!deficiency) {
      return isIncomplete();
    }

    return !isOriginalModified();
  };

  const isPending = () => {
    if (!deficiency) {
      //Was anything selected?
      return (
        selectedDeficiency.part ||
        selectedDeficiency.violation ||
        selectedDeficiency.remedy
      );
    }

    return isOriginalModified();
  };

  const disabled = isDisabled();

  const navigateBack = () => {
    navigate(-1);
  };

  const onBackClick = (force) => {
    //Check if something was modified and not updated
    if (!force && isPending()) {
      setShowConfirmPendingAbandon(true);
      return;
    }

    navigateBack();
  };

  const onSelectPart = (part) => {
    // user selected a part - save to local state
    setSelectedDeficiency((prev) => ({ ...prev, part }));

    // show the defficiency details component
    backToMainComponent();
  };

  const onSelectRemedy = (remedy) => {
    // user selected a remedy - save to local state
    setSelectedDeficiency((prev) => ({ ...prev, remedy }));

    // show the defficiency details component
    backToMainComponent();
  };

  const onSelectViolation = (violation) => {
    // user selected a violation - save to local state
    setSelectedDeficiency((prev) => ({ ...prev, violation }));

    // show the defficiency details component
    backToMainComponent();
  };

  const backToMainComponent = () => {
    setState((prev) => ({
      ...prev,
      componentToShow: DEFICIENCY_DETAILS_COMPONENTS.MAIN,
    }));
  };

  const onShowComponent = (comp) => {
    // should we show lists of resources? or the deficiency details component
    setState((prev) => ({ ...prev, componentToShow: comp }));
  };

  const render = () => {
    //console.log('render', state.componentToShow, elevatorParts);
    switch (state.componentToShow) {
      case DEFICIENCY_DETAILS_COMPONENTS.PART:
        return (
          <ResourceListPage
            title={"Elevator Part"}
            resources={elevatorParts}
            onSelect={onSelectPart}
            onBack={backToMainComponent}
          />
        );
      case DEFICIENCY_DETAILS_COMPONENTS.VIOLATION:
        return (
          <ResourceListPage
            title={"Violating Condition"}
            resources={violations}
            onSelect={onSelectViolation}
            onBack={backToMainComponent}
          />
        );
      case DEFICIENCY_DETAILS_COMPONENTS.REMEDY:
        return (
          <ResourceListPage
            title={"Remedy"}
            resources={remedies}
            onSelect={onSelectRemedy}
            onBack={backToMainComponent}
          />
        );
      default:
        return (
          <>
            <ConfirmModal
              title={"Confirm"}
              text={DEF_DETAIL_MESSAGES.PENDING_DISCLAIMER}
              show={showConfirmPendingAbandon}
              onClose={() => setShowConfirmPendingAbandon(false)}
              onConfirm={() => navigateBack()}
            />
            <DeficiencyDetailMain
              isLoading={isLoading}
              defGetStatus={defGetStatus}
              insStatus={insStatus}
              violation={violation}
              part={part}
              remedy={remedy}
              inspectionLine={line}
              onShowComponent={onShowComponent}
              onBack={onBackClick}
              disabled={disabled}
              isUpdate={defSuccess && deficiency}
            />
          </>
        );
    }
  };

  return <div>{render()}</div>;
};

export default InspectionDeficiencyDetails;
