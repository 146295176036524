import React, { useEffect, useState } from "react";
import { store } from "./redux/store.js";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration.js";
import { Stack } from "react-bootstrap";
import IconButton from "./internal/shared/components/buttons/_IconButton.jsx";
import { useServiceWorker } from "./hooks/useServiceWorker.js";

function AppSWUpdate() {
  const [showUpdate, setShowUpdate] = useState(false);
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  console.log("AppSWUpdate - update", showUpdate);
  // decides when to show the warning
  useEffect(() => {
    console.log("AppSWUpdate - sw", showReload, waitingWorker);

    if (showReload && waitingWorker) {
      if (!showUpdate) setShowUpdate(true);
    } else {
      if (showUpdate) setShowUpdate(false);
    }
  }, [waitingWorker, showReload, reloadPage]);

  if (!showUpdate) return <></>;

  return (
    <div className={"fade alert show m-0"}>
      <Stack direction="horizontal">
        <div>
          <h4>{"A new version is available!"}</h4>
          <h6>{"Refresh now"}</h6>
        </div>
        <IconButton
          className={"p-1"}
          iconClass={"ri-refresh-line"}
          size={"md"}
          onClick={reloadPage}
        />
      </Stack>
    </div>
  );
}

export default AppSWUpdate;
