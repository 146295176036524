import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getLockedStatus } from "../services/utils/inspectionType";
import { useInspections } from "./useInspections";

export const useInspectionLine = () => {
  const { inspectionLineId } = useParams();
  const [inspectionLine, setInspectionLine] = useState(null);

  const {
    inspections,
    isLoading: insLoading,
    isSuccess: insSuccess,
    status: insStatus
  } = useInspections();
  

  useEffect(() => {
    if (inspections) {
      const line = inspections.find(
        (c) => `${c.inspectionLineId}` === `${inspectionLineId}`
      );
      setInspectionLine(line);
    }
  }, [inspections, inspectionLineId]);

  //TODO: Revisit this, try to force refetch if inspLine is missing. To reproduce just do a page refresh.
  // useEffect(() => {
  //   console.log("insApi", inspApi);
  //   if (!inspectionLine && retryCount < MAX_QUERY_RETRIES) {
  //     console.log("insApi refetching");
  //     inspApi.refetch();
  //   }
  // }, [inspectionLine]);

  return {
    inspectionLine,
    isLoading: insLoading,
    isSuccess: !insLoading && insSuccess,
    lockedStatus: getLockedStatus(inspectionLine),
    status: insStatus,
  };
};
