import React, { useState } from "react";

// components
import { Col, Container, Row, Stack } from "react-bootstrap";
import Header from "../../../shared/components/header/_Header";
import DeficiencyDetailLine from "./_DeficiencyDetailLine";
import Label from "../../../shared/components/label/_LabelGroup";
import LargeButton from "../../../shared/components/buttons/_LargeButton";

//services
import { DEFICIENCY_DETAILS_COMPONENTS } from "./_DeficiencyDetailComponents";
import StatusMessage from "../../../shared/components/status-message/_StatusMessage";
import PageError from "../../../shared/components/error/_PageError";
import PageSpinner from "../../../shared/components/spinner/_PageSpinner";
import { useUpsertDeficiencyMutation } from "../../../../redux/features/deficiencies/deficiencyApiSlice";
import { buildFromMutation } from "../../../../services/utils/statusUtils";
import { useParams } from "react-router-dom";

const DeficiencyDetailMain = ({
  isLoading,
  insStatus,
  defGetStatus,
  inspectionLine: line,
  onShowComponent,
  onBack,
  disabled,
  violation,
  part,
  remedy,
  isUpdate,
}) => {
  const { inspectionLineId, deficiencyId } = useParams();
  const [defUpdating, setDefUpdating] = useState(false);
  const [defUpdateStatus, setDefUpdateStatus] = useState(null);
  const [showBtnError, setShowBtnError] = useState(false);
  const [upsertDeficiency] = useUpsertDeficiencyMutation();

  const onSelectPart = () => {
    onShowComponent(DEFICIENCY_DETAILS_COMPONENTS.PART);
  };

  const onSelectRemedy = () => {
    onShowComponent(DEFICIENCY_DETAILS_COMPONENTS.REMEDY);
  };

  const onSelectViolation = () => {
    onShowComponent(DEFICIENCY_DETAILS_COMPONENTS.VIOLATION);
  };

  const onSubmitDeficiency = async () => {
    if (disabled) {
      setShowBtnError(true);
      return;
    }

    if(defUpdating) return;

    setShowBtnError(false);
    setDefUpdating(true);

    let payload = {
      deficiencyId: deficiencyId,
      inspectionLineId: inspectionLineId,
      inspectionId: line.inspectionId,
      customerId: line.customerId,
      buildingId: line.buildingId,
      deviceId: line.deviceId,
      partId: part.id,
      isBuildingResponsibility: part.isBuildingResponsibility ? 1 : 0,
      violationId: violation.id,
      remedyId: remedy.id,
    };

    const response = await upsertDeficiency(payload);
    const updateStatus = buildFromMutation(response);
    setDefUpdating(false);

    if (updateStatus.hasError) {
      setDefUpdateStatus(updateStatus);
      return;
    }

    onBack(true);
  };

  const renderMainContent = () => {
    if (defGetStatus?.hasError)
      return <PageError show={true} status={defGetStatus} />;

    if (insStatus?.hasError)
      return <PageError show={true} status={insStatus} />;

    if (isLoading) return <PageSpinner show={true} />;

    return (
      <Stack gap={3} className="mt-2">
        <StatusMessage
          status={defUpdateStatus}
          show={defUpdateStatus?.hasError}
        />

        <DeficiencyDetailLine
          name={"Parts"}
          text={"SELECTED ELEVATOR PART"}
          onSelect={onSelectPart}
        />
        <Label text={`Part: ${part?.item}`} textClass={"px-2"} />
        <Label text={`Location: ${part?.description}`} textClass={"px-2"} />

        <DeficiencyDetailLine
          name={"Violations"}
          text={"SELECTED VIOLATING CONDITION"}
          onSelect={onSelectViolation}
        />
        <Label
          text={`Violation:  ${violation?.item} - ${violation?.description}`}
          textClass={"px-2"}
        />

        <DeficiencyDetailLine
          name={"Remedies"}
          text={"SELECTED REMEDY"}
          onSelect={onSelectRemedy}
        />
        <Label
          text={`Remedy: ${remedy?.item} - ${remedy?.description}`}
          textClass={"px-2"}
        />
        {showBtnError && (
          <Label text={"Please fill out all the above fields and try again!"} />
        )}
        <LargeButton
          loading={defUpdating}
          onClick={onSubmitDeficiency}
          title={isUpdate ? "Update" : "Add"}
          variant={defUpdating || disabled ? "secondary" : null}
        />
      </Stack>
    );
  };

  return (
    <Container>
      <Row>
        <Col>
          <Header
            title={"Selected Deficiencies"}
            subTitle={"Click the edit icon to make changes"}
            onBack={onBack}
          />
        </Col>
      </Row>
      <Row>
        <Col>{renderMainContent()}</Col>
      </Row>
    </Container>
  );
};

export default DeficiencyDetailMain;
