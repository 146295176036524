import React from "react";
import { useNavigate } from "react-router-dom";
import {
  INTERNAL_ROUTES,
  INSPECTION_ROUTES_PARAMS,
} from "../../routing/nav_routes";

//components
import { Stack } from "react-bootstrap";
import SchedulerLine from "./components/line/_SchedulerLine";
import HeaderWithDaySelection from "../shared/components/header/_HeaderWithDaySelection";

//services

// hooks
import { useInspections } from "../../hooks/useInspections";
import PageError from "../shared/components/error/_PageError";
import PageSpinner from "../shared/components/spinner/_PageSpinner";

const Scheduler = () => {
  const { inspections, isLoading, isSuccess, status, refetch } =
    useInspections();

  const navigate = useNavigate();

  const onLineClick = (line) => {
    // set lineId to route and navigate to inspection line
    navigate(
      INTERNAL_ROUTES.INSPECTION.replace(
        INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
        line.inspectionLineId
      )
    );
  };

  //console.log(process.env.NODE_ENV);
  console.log(process.env);

  const buildLines = () => {
    return inspections?.map((line, i) => {
      return (
        <SchedulerLine
          inspectionLine={line}
          onCardSelect={onLineClick}
          key={i}
        />
      );
    });
  };

  if (isLoading) return <PageSpinner show={isLoading} />;

  if (!isSuccess)
    return <PageError status={status} show={!isSuccess} refresh={refetch} />;

  if (isSuccess) {
    return (
      <div>
        <HeaderWithDaySelection schedulesCount={inspections?.length} />
        <Stack direction="vertical" className="bg-success-lighten">
          {buildLines()}
        </Stack>
      </div>
    );
  }
};
export default Scheduler;
