import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// components
import InspectorMain from "./components/_InspectorMain";
import AddInspector from "./components/_AddInspector";

//services
import { useUpdateInspectorMutation } from "../../redux/features/inspectionLine/inspectionApiSlice";
import { buildFromMutation } from "../../services/utils/statusUtils";

//hooks
import { useInspectionLine } from "../../hooks/useInspectionLine";
import { useElevatorContacts } from "../../hooks/useElevatorContacts";
import { INSPECTOR_MESSAGES } from "./_InspectorConsts";
import ConfirmModal from "../shared/components/modal/_ConfirmModal";

const Inspector = () => {
  const navigate = useNavigate();
  const { inspectionLine } = useInspectionLine();
  const { fetch } = useElevatorContacts();
  // update inspector in db
  const [updateInspector] = useUpdateInspectorMutation();
  const [contactDetails, setContactDetails] = useState({
    inspectionLineId: 0,
    elevatorContactId: 0,
    testingCompanyId: 0,

    firstName: "",
    lastName: "",
    license: "",
    status: null,
  });

  const [showAddInspector, setShowAddInspector] = useState(false);

  const [elevatorContacts, setElevatorContacts] = useState({
    contacts: [],
    status: null,
  });

  const [showConfirmPendingAbandon, setShowConfirmPendingAbandon] = useState(false);
  const [selectedItemValue, setSelectedItemValue] = useState(null);

  useEffect(() => {
    if (inspectionLine) {
      const asyncFetch = async () => {
        const { contactDetails, elevatorContacts, status } = await fetch(
          inspectionLine
        );

        setContactDetails(contactDetails);
        setElevatorContacts({ contacts: elevatorContacts, status: status });
      };

      asyncFetch();
    }
  }, [inspectionLine, fetch]);

  const onBackClick = () => {
    if (isPending()) {
      setShowConfirmPendingAbandon(true);
      return;
    }

    navigateBack();
  };

  const navigateBack = () => {
    navigate(-1);
  };

  const isPending = () => {
    if (selectedItemValue) {
      //Was selected?
      return true;
    }

    return false;
  };

  const onUpdateInspector = async (opt) => {
    // update mutation into redux
    // we need the id of the elevator contact
    // the description of the elevator contact has the name and license in one

    let payload = {
      inspectionLineId: contactDetails.inspectionLineId,
      elevatorContactId: opt.id,
    };

    const response = await updateInspector(payload);
    const status = buildFromMutation(
      response,
      "Updated inspector!",
      "Failed to update inspector!"
    );

    if (status.hasError) {
      setContactDetails((prev) => ({ ...prev, status: status }));
      return;
    }

    onBackClick();
    return;
  };

  const onAddInspector = async (payload) => {
    setShowAddInspector(true);
  };

  const onSelectedItemValue = (item) =>{
    setSelectedItemValue(item);
  }

  const render = () => {
    if (showAddInspector)
      return (
        <AddInspector elevatorContacts={elevatorContacts} inspectionLine={inspectionLine} onBack={onBackClick} />
      );

    return (
      <>
        <ConfirmModal
          title={"Confirm"}
          text={INSPECTOR_MESSAGES.PENDING_DISCLAIMER}
          show={showConfirmPendingAbandon}
          onClose={() => setShowConfirmPendingAbandon(false)}
          onConfirm={() => navigateBack()}
        />
        <InspectorMain
          elevatorContacts={elevatorContacts}
          state={contactDetails}
          onUpdate={onUpdateInspector}
          onBack={onBackClick}
          onAdd={onAddInspector}
          onSelectedItem = {onSelectedItemValue} 
        />
      </>
    );
  };

  return <div>{render()}</div>;
};

export default Inspector;
