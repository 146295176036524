export const ENDPOINTS = {
  API: function () {
    return process.env[ENV_VARIABLE_NAMES.BASE_API_URL];
  },
};

export const VERSION = "1.0.68";

export const API_METHOD = {
  POST: "POST",
  GET: "GET",
  DELETE: "DELETE",
};

export const LOCAL_STORAGE = {
  PROFILE: "live-profile",
  TOKEN: "live-token",
};

export const ENV_VARIABLE_NAMES = {
  BASE_API_URL: "REACT_APP_API_BASE_URL",
};


export const MAX_QUERY_RETRIES = 3; 