import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

// components
import { Col, Container, Row, Stack } from "react-bootstrap";
import Header from "../../shared/components/header/_Header";
import PhoneDetailLine from "./components/_PhoneDetailLine";
import DetailLine from "./components/_DetailLine";
import PageSpinner from "../../shared/components/spinner/_PageSpinner";
import PageError from "../../shared/components/error/_PageError";

//services
import { useInspectionLine } from "../../../hooks/useInspectionLine";
import { danger } from "../../../services/utils/statusUtils";
import { INSPECTION_ERRORS } from "../../../services/constants/inspectionMessages";

//css

const InspectionDetails = () => {
  const navigate = useNavigate();

  const { inspectionLine, isLoading, status, isSuccess } = useInspectionLine();

  const onBackClick = () => {
    navigate(-1);
  };

  const renderMainContent = () => {
    if (isLoading) return <PageSpinner show={true} />;
    if (!isSuccess) return <PageError status={status} show={true} />;
    if (!inspectionLine)
      return (
        <PageError
          status={danger(INSPECTION_ERRORS.FAILED_TO_LOAD_LINE)}
          show={true}
        />
      );

    return (
      <Stack gap={2} className="px-2">
        <DetailLine
          titleOne={"INSP TIME"}
          detailsOne={moment(inspectionLine.inspectionTime, "HH:mm").format(
            "h:mm A"
          )}
          titleTwo={"DEVICE"}
          detailsTwo={inspectionLine.deviceName}
        />
        <DetailLine
          titleOne={"INSP TYPE"}
          detailsOne={inspectionLine.inspectionType}
          titleTwo={"INSP ID"}
          detailsTwo={inspectionLine.inspectionId}
        />
        <DetailLine
          titleOne={"BUILDING ADDRESS"}
          detailsOne={inspectionLine.buildingAddress}
        />
        <DetailLine titleOne={"BOROUGH"} detailsOne={inspectionLine.borough} />
        <DetailLine
          titleOne={"CUSTOMER"}
          detailsOne={inspectionLine.customerName}
        />
        <DetailLine
          titleOne={"ELEVATOR CO"}
          detailsOne={inspectionLine.elevatorCompany}
        />
        <DetailLine
          titleOne={"TESTING CO"}
          detailsOne={inspectionLine.testingCompany}
        />
        <PhoneDetailLine
          title={"AGENT"}
          name={inspectionLine.agentFullName}
          phone={inspectionLine.agentPhone}
        />
        <PhoneDetailLine
          title={"SUPER"}
          name={inspectionLine.superintendent}
          phone={inspectionLine.superPhone}
        />
      </Stack>
    );
  };

  return (
    <Container>
      <Row>
        <Col>
          <Header title={"Inspection details"} onBack={onBackClick} />
        </Col>
      </Row>
      <Row>
        <Col>{renderMainContent()}</Col>
      </Row>
    </Container>
  );
};

export default InspectionDetails;
