import React, { useState } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

// components
import Header from "../shared/components/header/_Header";
import LargeButton from "../shared/components/buttons/_LargeButton";
import ConfirmModal from "../shared/components/modal/_ConfirmModal";
import CompletedStatus from "../shared/components/status-message/_CompletedStatus";
import PageError from "../shared/components/error/_PageError";
import PageSpinner from "../shared/components/spinner/_PageSpinner";
import StatusMessage from "../shared/components/status-message/_StatusMessage";

//services
import { isNullOrWhitespace } from "../../services/utils/stringUtils";

//hooks
import { useInspectionLine } from "../../hooks/useInspectionLine";
import { useCommentsUpdate } from "../../hooks/useCommentsUpdate";
import { INSPECTION_ERRORS } from "../../services/constants/inspectionMessages";
import { danger } from "../../services/utils/statusUtils";
import { COMMENTS_MESSAGES } from "./_CommentsConsts";
//

const Comments = () => {
  const [state, setState] = useState({
    comments: null,
    showConfirm: false,
    updatedStatus: null,
  });

  const { inspectionLineId } = useParams();
  const navigate = useNavigate();

  const { inspectionLine, isLoading, isSuccess, lockedStatus, status } =
    useInspectionLine();
  const { isLoading: isUpdating, update } = useCommentsUpdate();

  const [showConfirmPendingAbandon, setShowConfirmPendingAbandon] = useState(false);

  const onBackClick = () => {
    // navigate(
    //   INTERNAL_ROUTES.INSPECTION.replace(
    //     INSPECTION_ROUTES_PARAMS.INSPECTION_LINE_ID,
    //     inspectionLineId
    //   )
    // );

    if (isPending()) {
      setShowConfirmPendingAbandon(true);
      return;
    }

    navigateBack();
  };

  const navigateBack = () => {
    navigate(-1);
  };

  const isPending = () => {
    const normalize = (value) => value ?? "";
    if (normalize(state.comments) !== normalize(inspectionLine?.comments)) {
      return true;
    }

    return false;
  };


  const onUpdateClick = () => {
    if (lockedStatus.isLocked) return;

    if (isNullOrWhitespace(state.comments)) {
      setState((prevState) => ({ ...prevState, showConfirm: true }));
      return;
    }

    setState((prevState) => ({
      ...prevState,
      updatedStatus: null,
      updating: true,
    }));

    updateComments();
  };

  const updateComments = async () => {
    const status = await update(inspectionLineId, state.comments);
    setState((prevState) => ({
      ...prevState,
      updatedStatus: status,
      updating: false,
    }));
  };

  const onCommentsChange = (value) => {
    if (lockedStatus.isLocked) return;

    setState((prevState) => ({
      ...prevState,
      comments: value,
      updatedStatus: null,
    }));
  };

  const closeModal = () => {
    setState((prevState) => ({ ...prevState, showConfirm: false }));
  };

  const confirmModal = () => {
    setState((prevState) => ({
      ...prevState,
      showConfirm: false,
      updatedStatus: null,
    }));

    updateComments();
  };

  const renderMainContent = () => {
    console.log(
      "renderMainContent",
      "isLoading",
      isLoading,
      "isSuccess",
      isSuccess,
      "line",
      inspectionLine
    );

    if (isLoading) {
      return <PageSpinner show={isLoading} />;
    }

    if (!isSuccess) {
      return <PageError status={status} show={true} />;
    }

    if (!inspectionLine)
      return (
        <PageError
          status={danger(INSPECTION_ERRORS.FAILED_TO_LOAD_LINE)}
          show={true}
        />
      );

    const value = state.comments ?? inspectionLine?.comments;
    return (
      <Stack gap={2} className="px-2">
        <CompletedStatus lockedStatus={lockedStatus} />
        <StatusMessage status={state.updatedStatus} />
        <label htmlFor="comments" className="form-label ">
          <h4 className={`fw-light m-0`}>COMMENTS</h4>
        </label>
        <textarea
          className="form-control fs-4"
          id="comments"
          rows="13"
          onChange={(e) => onCommentsChange(e.target.value)}
          value={value ?? ""}
          disabled={lockedStatus.isLocked}
        ></textarea>
        <LargeButton
          title="Update"
          onClick={onUpdateClick}
          disabled={lockedStatus.isLocked || isUpdating}
          loading={isUpdating}
        />
      </Stack>
    );
  };

  return (
    <Container>
      <ConfirmModal
        show={state.showConfirm}
        text={"Are you sure?"}
        title={"You are about to empty the comments!"}
        onConfirm={confirmModal}
        onClose={closeModal}
      />
       <ConfirmModal
          title={"Confirm"}
          text={COMMENTS_MESSAGES.PENDING_DISCLAIMER}
          show={showConfirmPendingAbandon}
          onClose={() => setShowConfirmPendingAbandon(false)}
          onConfirm={() => navigateBack()}
        />
      <Row>
        <Col>
          <Header title={"Comments"} onBack={onBackClick} />
        </Col>
      </Row>
      <Row>
        <Col>{renderMainContent()}</Col>
      </Row>
    </Container>
  );
};

export default Comments;
