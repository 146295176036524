export const mapUsersToResources = (users) => {
  console.log("map", users);
  if (!users) return [];

  return users.map((u) => {
    console.log("map - user", u);

    return {
      id: u.id,
      item: u.fullName,
      description: u.license
        ? `${u.license} (${u.userId})`
        : `License N/A (${u.userId})`,
    };
  });
};

export const getActingAsUser = (currentUser, users) => {
  if (currentUser) {
    //console.log("getActingAsUserName", currentUser, users);
    if (users && currentUser.idOverride) {
      const overrideUser = users.find((u) => u.id === currentUser.idOverride);
      if (overrideUser) {
        return overrideUser;
      }
    }

    return currentUser;
  }

  return null;
};
