import React from "react";
import ReactDOM from "react-dom/client";

import "regenerator-runtime/runtime";

//import "bootstrap/dist/css/bootstrap.min.css";

// For Saas import Saas.scss
import "./assets/scss/Saas.scss";

import "./index.css";
import App from "./App.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
