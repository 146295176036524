import { useParams } from "react-router-dom";
import { useGetDeficienciesQuery } from "../redux/features/deficiencies/deficiencyApiSlice";
import { buildFromQuery } from "../services/utils/statusUtils";
import { DEFS_ERRORS } from "../services/constants/deficiencyMessages";

export const useDeficiencies = () => {
  const { inspectionLineId } = useParams();

  //TODO: Check is fetches correctly after first fetch. Probably should build a dynamic tag with the inspection line id.
  const defApi = useGetDeficienciesQuery(
    {
      inspectionLineId: inspectionLineId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const refetch = () => {
    console.log("refetching");
    defApi.refetch();
  };

  const failMsg = DEFS_ERRORS.FAILED_TO_LOAD_DEFS;
  const status = buildFromQuery(defApi, undefined, failMsg);
  const loading = defApi.isLoading || defApi.isFetching;
  //console.log("refetching done", defApi.data?.results.length);
  
  return {
    deficiencies: defApi.data?.results,
    isLoading: loading,
    isSuccess: !loading && !status.hasError,
    status,
    refetch,
  };
};
