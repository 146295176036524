import { INSPECTION_VALIDATIONS } from "../constants/inspectionMessages";
import * as ins from "../utils/inspectionType";

export const validateLine = (line, nbOfDeficiencies) => {
  if (!line) return INSPECTION_VALIDATIONS.MISSING_INSPECTION_LINE;

  const validator = new InspectionValidator(line, nbOfDeficiencies);

  return validator.validate();
};

class InspectionValidator {
  #line;
  #isPeriodic;
  #isCat1;
  #isCat5;
  #isCat1OrPeriodic;
  #cat1Satisfactory;
  #cat5Satisfactory;
  #cat1Unsatisfactory;
  #nbOfDeficiencies;

  constructor(line, nbOfDeficiencies) {
    this.#line = line;
    this.#isPeriodic = ins.isPeriodic(line);
    this.#isCat1 = ins.isCat1(line);
    this.#isCat5 = ins.isCat5(line);
    this.#isCat1OrPeriodic = this.#isCat1 || this.#isPeriodic;
    this.#cat1Satisfactory = ins.isCat1Satisfactory(line);
    this.#cat5Satisfactory = ins.isCat5Satisfactory(line);
    this.#cat1Unsatisfactory = ins.isCat1Unsatisfactory(line);
    this.#nbOfDeficiencies = nbOfDeficiencies;
  }

  validate() {
    let error = this.#validateCat1Result();
    if (error) return error;

    error = this.#validateCat5Result();
    if (error) return error;

    error = this.#validateDeficiencies();
    if (error) return error;

    error = this.#validateInspector();
    if (error) return error;

    return null;
  }

  #validateInspector() {
    if (
      !this.#isPeriodic &&
      (!this.#line.elevatorContactId || !this.#line.elevatorContactId === 0)
    )
      return INSPECTION_VALIDATIONS.INSPECTOR_REQUIRED;

    return null;
  }

  #validateDeficiencies() {
    if (this.#cat1Unsatisfactory && this.#nbOfDeficiencies <= 0)
      return INSPECTION_VALIDATIONS.DEFS_REQUIRED_WHEN_UNSATISFACTORY;

    return null;
  }

  #validateCat1Result() {
    if ((this.#isCat1 || this.#isPeriodic) && ins.isCat1NA(this.#line)) {
      return INSPECTION_VALIDATIONS.MISSING_CAT_RESULT;
    }

    return null;
  }

  #validateCat5Result() {
    if (this.#isCat5 && ins.isCat5NA(this.#line)) {
      return INSPECTION_VALIDATIONS.MISSING_CAT_RESULT;
    }

    return null;
  }
}
