import React from "react";

const Label = ({ text, className, type }) => {
  const getTypeClass = () => {
    switch (type) {
      case "light":
        return "fw-light";
      case "normal":
        return "";
      default:
        return "fw-bold";
    }
  };

  return <h4 className={`${getTypeClass()} ${className}`}>{text}</h4>;
};

export default Label;
