export const INSPECTION_LABELS = {
  ERROR_MODAL_CANT_COMPLETE: "Can't complete",

  HEADER_TITLE: "Inspection Line",

  INSPECTION_DEVICE: "Device",
  INSPECTION_STATUS: "Status",
  INSPECTION_ID: "Insp. Id",
  INSPECTION_DATE: "Insp. Date",

  INSPECTION_BRAKE_COMPLIANCE: "BRAKE COMPLIANCE - MACHINE MANUFACTURER",
  INSPECTION_BRAKE_COMPLIANCE_DESCRIPTION: (
    brakeComplianceDisplay,
    machineManufacturer
  ) =>
    (brakeComplianceDisplay !== null
      ? brakeComplianceDisplay
      : "Select Status") +
    " - " +
    (machineManufacturer !== null
      ? machineManufacturer
      : "Select Manufacturer"),

  INSPECTION_COMMENTS: "INSPECTION DEVICE COMMENTS",
  INSPECTION_COMMENTS_DESCRIPTION: "View Comments",

  INSPECTION_INSPECTOR: "ELEVATOR INSPECTOR",
  INSPECTION_INSPECTOR_DESCRIPTION: (fullName, license) =>
    fullName ? fullName + " - " + license : "Select Inspector",

  INSPECTION_DEF: (nbOfDeficiencies) =>
    `DEFICIENCIES (${nbOfDeficiencies ?? "N/A"})`,
  INSPECTION_DEF_DESCRIPTION: "View Device Deficiencies",

  INSPECTION_DEF_HISTORY: "DEFICIENCY HISTORY",
  INSPECTION_DEF_HISTORY_DESCRIPTION: "Previous Inspection Results",

  INSPECTION_IMAGES: "INSPECTION PHOTOS",
  INSPECTION_IMAGES_DESCRIPTION: "View/Take Photos",

  COMPLETE_BTN_LOCKED: "Line completed, cannot update.",
};

export const MOBILE_STATUS = {
  TODO: "ToDo",
  COMPLETE: "Complete",
};

export const MOBILE_STATUS_BUTTON = {
  TODO: "To Do",
  COMPLETE: "Complete",
};

export const CAT_1_PERIODIC_RESULTS_LABELS = {
  0: "N/A",
  1: "Satisfactory",
  2: "Unsatisfactory",
};

export const CAT_1_PERIODIC_RESULTS_INDEXES = {
  NA: 0,
  SATISFACTORY: 1,
  UNSATISFACTORY: 2,
};

export const CAT_1_PERIODIC_STATUS = {
  NA: "N/A",
  SATISFACTORY: "Satisfactory",
  UNSATISFACTORY: "Unsatisfactory",
};

export const CAT_5_STATUS = {
  NA: "N/A",
  SATISFACTORY: "Satisfactory",
};

export const CAT_5_INDEXES = {
  NA: 0,
  SATISFACTORY: 1,
};

export const CAT_TYPES = {
  CAT1: 1,
  CAT1_CAT5: 2,
  CAT5: 3,
  PERIODIC: 7,
};

export const CAT5RESULTS = [CAT_5_STATUS.NA, CAT_5_STATUS.SATISFACTORY];

export const CAT1RESULTS = [
  CAT_1_PERIODIC_STATUS.NA,
  CAT_1_PERIODIC_STATUS.SATISFACTORY,
  CAT_1_PERIODIC_STATUS.UNSATISFACTORY,
];
