import React, { useState } from "react";
import { string } from "yup";

// components
import { Col, Container, Row, Stack } from "react-bootstrap";
import Header from "../../shared/components/header/_Header.jsx";

//services
import LargeButton from "../../shared/components/buttons/_LargeButton.jsx";
import TextInput from "../../shared/components/input/_TextInput.jsx";
import { useAddInspector } from "../../../hooks/useAddInspector.js";
import StatusMessage from "../../shared/components/status-message/_StatusMessage.jsx";
import Label from "../../shared/components/label/_Label.jsx";
import { INSPECTOR_VALIDATIONS } from "../../../services/constants/inspectorMessages.js";

let licenseValidation = string()
  .required("Required")
  .matches(new RegExp("^[0-9]{6}$"), "Must be a 6 digit number.");
let lastNameValidation = string()
  .required("Required")
  .matches(
    new RegExp("^[A-Za-z]{1,25}$"),
    "Only characters are allowed, at most 25."
  );
let firstNameValidation = string()
  .required("Required")
  .matches(
    new RegExp("^[A-Za-z]{1,25}$"),
    "Only characters are allowed, at most 25."
  );

const AddInspector = ({ elevatorContacts, inspectionLine, onBack }) => {
  const [newInspectorDetails, setNewInspectorDetails] = useState({
    firstName: "",
    lastName: "",
    license: "",
  });
  const [addState, setAddState] = useState({
    loading: false,
    status: null,
    firstNameError: null,
    lastNameError: null,
    licenseError: null,
    showBtnError: false,
  });

  const { add } = useAddInspector();

  const disabled = !(
    newInspectorDetails.firstName &&
    newInspectorDetails.lastName &&
    newInspectorDetails.license
  );

  const isValid = () => {
    let firstNameError = validate(
      firstNameValidation,
      newInspectorDetails.firstName
    );
    let lastNameError = validate(
      lastNameValidation,
      newInspectorDetails.lastName
    );

    let licenseError = validate(licenseValidation, newInspectorDetails.license);

    licenseError = licenseError ?? isLicenseUnique(newInspectorDetails.license);

    setAddState((prev) => ({
      ...prev,
      firstNameError: firstNameError,
      lastNameError: lastNameError,
      licenseError: licenseError,
    }));

    return !(firstNameError || lastNameError || licenseError);
  };

  const isLicenseUnique = (license) => {
    const contact = elevatorContacts.contacts.find((_) => {
      const match = `${_.license}` === `${license}`;
      return match;
    });

    if (contact) {
      return INSPECTOR_VALIDATIONS.INSPECTOR_LICENSE_EXISTS(license, contact.fullName);
    }
    return null;
  };

  const validate = (yupValidation, value) => {
    try {
      yupValidation.validateSync(value);
      return null;
    } catch (error) {
      console.log(error.message, error.path);
      return error.message;
    }
  };

  const onSubmitNewInspector = async () => {
    if (disabled) {
      setAddState((prev) => ({ ...prev, showBtnError: true }));
      return;
    }

    setAddState((prev) => ({ ...prev, loading: false, status: null }));

    if (!isValid()) return;

    setAddState({ loading: true, status: null });

    const status = await add(
      inspectionLine.inspectionLineId,
      inspectionLine.testingCompanyId,
      newInspectorDetails.firstName,
      newInspectorDetails.lastName,
      newInspectorDetails.license
    );

    setAddState({ loading: false, status: status });

    if (!status.hasError) onBack();
  };

  const renderMainContent = () => {
    return (
      <Stack gap={2}>
        <StatusMessage status={addState.status} />
        <TextInput
          forKey={"firstName"}
          label={"First Name*"}
          error={addState.firstNameError}
          inputClassName={
            addState.firstNameError ? "border border-2 border-danger" : ""
          }
          initialValue={newInspectorDetails.firstName}
          onValueChanged={(value) => {
            setNewInspectorDetails((prev) => ({
              ...prev,
              firstName: value,
            }));
          }}
        />
        <TextInput
          forKey={"lastName"}
          label={"Last Name*"}
          error={addState.lastNameError}
          inputClassName={
            addState.lastNameError ? "border border-2 border-danger" : ""
          }
          initialValue={newInspectorDetails.lastName}
          onValueChanged={(value) => {
            setNewInspectorDetails((prev) => ({
              ...prev,
              lastName: value,
            }));
          }}
        />
        <TextInput
          forKey={"license"}
          label={"License Number*"}
          error={addState.licenseError}
          inputType={"number"}
          inputClassName={
            addState.licenseError ? "border border-2 border-danger" : ""
          }
          initialValue={newInspectorDetails.license}
          onValueChanged={(value) => {
            setNewInspectorDetails((prev) => ({
              ...prev,
              license: value,
            }));
          }}
        />
        <Stack direction="vertical" className="mt-2">
          {addState.showBtnError ? (
            <Label
              text={"Please fill out all the above fields and try again!"}
            />
          ) : null}
          <LargeButton
            disabled={addState.loading}
            loading={addState.loading}
            onClick={onSubmitNewInspector}
            title={"Add!"}
            variant={disabled ? "secondary" : null}
          />
        </Stack>
      </Stack>
    );
  };

  return (
    <Container>
      <Row>
        <Col>
          <Header
            title={"Add New Inspector"}
            subTitle={"Please fill out all the fields"}
            onBack={onBack}
          />
        </Col>
      </Row>
      <Row>
        <Col>{renderMainContent()}</Col>
      </Row>
    </Container>
  );
};

export default AddInspector;
